@import "./universal.scss";

.shop-filter {
  text-align: left;

  h2 {
    margin: 0;
    margin-bottom: 10px;
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    gap: 5px;
    font-size: 1.2em;

    input[type="checkbox"] {
      height: 1.2em;
      width: 1.2em;

      &:checked {
        background: $green;
      }
    }
  }

  button {
    margin-top: 20px;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    background: $foreground;
    color: $primary-text;
    cursor: pointer;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

    &:active {
      transform: scale(.95);
    }
  }
}

// desktop
@media screen and (min-width: $desktop-breakpoint) {
  .shop-filter {
    padding: 20px;
    padding-left: 40px;

    .mobile-filter-toggle {
      display: none;
    }
  }
}

// mobile
@media screen and (max-width: $mobile-breakpoint) {
  .shop-filter {
    padding: 0px 30px 20px;
    font-size: 1.5em;

    .mobile-filter-toggle {
      button {
        @include flex(row);
        align-items: center;
        gap: 10px;

        img {
          width: 1.5em;
          height: 1.5em;
          filter: invert(100%) sepia(3%) saturate(205%) hue-rotate(317deg) brightness(116%) contrast(90%);
        }
      }
    }

    .filter-options {
      margin-top: 20px;

      &.hidden {
        display: none;
      }
    }
  }
}