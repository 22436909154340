@import "universal.scss";

.shop {
  .item-preview {
    @include flex(row);
    z-index: 999;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(8px);
    cursor: pointer;

    &.hidden {
      display: none;
    }

    img {
      object-fit: cover;
      max-width: 60%;
      max-height: 80%;
      filter: drop-shadow(0 0 10px rgba(0, 0, 0, .5));
    }
  }

  .shop-container {
    .products-container {
      display: grid;
    }

  }

  .page-selector {
    @include flex(row);
    gap: 5px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    span {
      font-size: 1.5em;
    }

    button {
      width: 40px;
      height: 40px;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;

      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }

      img {
        height: 100%;
        width: 100%;
        filter: invert(100%) sepia(3%) saturate(205%) hue-rotate(317deg) brightness(116%) contrast(90%);
      }
    }
  }

  // desktop
  @media screen and (min-width: $desktop-breakpoint) {
    .shop-container {
      @include flex(row);
      padding-top: 40px;

      .products-container {
        flex: 5;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        padding: 0 40px;

        .product {
          margin: 20px;
          padding: 20px;
        }
      }
    }
  }

  // mobile
  @media screen and (max-width: $mobile-breakpoint) {
    .shop-container {
      display: block;
      padding-top: 20px;

      .products-container {
        padding: 0 20px;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      }
    }
  }
}