@font-face {
  font-family: "Kalam";
  src: url("../fonts/Kalam/Kalam-Regular.ttf");
}
.hero {
  position: relative;
  margin-top: -30px;
  width: 100%;
  height: 350px;
  background-color: #222222;
  background-size: cover;
  box-shadow: inset 0 -150px 100px 20px rgba(0, 0, 0, 0.75);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.hero .hero-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px 80px;
  text-align: left;
}
.hero .hero-content .headline {
  display: block;
  font-family: Kalam;
  font-size: 5em;
  color: #704FF6;
  filter: drop-shadow(0 0 4px #030303);
}
.hero .hero-content .subheadline {
  display: block;
  color: #E0E0E0;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
}

@media screen and (min-width: 801px) {
  .hero .hero-content .headline {
    font-size: 5em;
  }
  .hero .hero-content .subheadline {
    margin-top: -30px;
    font-size: 2em;
  }
}
@media screen and (max-width: 800px) {
  .hero {
    height: auto;
  }
  .hero .hero-content {
    position: relative;
    padding: 60px 40px;
    text-align: center;
  }
  .hero .hero-content .headline {
    font-size: 4em;
    line-height: 100%;
  }
  .hero .hero-content .subheadline {
    font-size: 2em;
  }
}/*# sourceMappingURL=Hero.css.map */