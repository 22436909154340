@import "universal.scss";

.launch {
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  height: 100vh;
  align-items: center;
  justify-content: center;
  user-select: none;

  .message {

    img {
      width: 200px;
    }

    p {
      font-size: 1.5em;
    }
  }

  .countdown {

    .time-remaining {
      font-size: 3em;

      .label {
        color: $tertiary-text;
      }
    }
  }
}

@media screen and (min-width: $desktop-breakpoint) {
  .launch {
    @include flex(row);
    padding: 100px;

    .message {
      width: 50%;
      padding: 40px;

      img {
        width: 200px;
      }
    }

    .countdown {
      width: 50%;
      padding: 40px;

      .time-remaining {

        .label {}
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .launch {
    @include flex(column);
    padding: 40px;

    .message {
      text-align: center;

      img {
        margin: 0 auto;
      }
    }

    .countdown {

      .time-remaining {

        .label {}
      }
    }
  }
}