.post .post-container .thumbnail {
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 100%;
  max-height: 70vh;
  border-radius: 20px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.post .post-container .post-content .title {
  margin: 0;
}
.post .post-container .post-content .date-posted {
  font-size: 1.5em;
  color: #585858;
}
.post .post-container .post-content .body {
  font-size: 1.2em;
}
.post .post-container .post-content .related-items {
  margin-top: 50px;
}
.post .post-container .post-content .related-items .product {
  margin: 0 auto;
  width: 200px;
}

@media screen and (min-width: 801px) {
  .post {
    padding: 20px 40px;
  }
  .post .back-button:hover {
    text-decoration: underline;
  }
  .post .post-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
  .post .post-container .thumbnail {
    max-width: 50%;
    max-height: 70vh;
    border-radius: 20px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
  .post .post-container .post-content {
    text-align: left;
  }
  .post .post-container .post-content .related-items {
    width: 200px;
  }
  .post .post-container .post-content .related-items .product {
    margin: 0;
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .post {
    padding: 0 20px;
  }
  .post .thumbnail {
    margin-bottom: 20px;
  }
  .post .post-container .post-content .body {
    text-align: left;
  }
}/*# sourceMappingURL=Post.css.map */