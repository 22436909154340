@font-face {
  font-family: "Nunito Sans";
  src: url("./resources/fonts/Nunito_Sans/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf");
}
@font-face {
  font-family: Alata;
  src: url("./resources/fonts/Alata/Alata-Regular.ttf");
}
@font-face {
  font-family: Banco;
  src: url("./resources/fonts/Banco/Banco-Regular.ttf");
}
html,
body {
  background-color: #030303 !important;
  background-image: url("https://global.discourse-cdn.com/pocketgems/uploads/episodeinteractive/original/4X/9/5/0/9501d4ae192161a7042de423032df3e9228b7624.jpeg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.App {
  margin: 0 auto;
  max-width: 1500px;
  width: 100%;
  font-family: Nunito Sans;
  font-size: 18px;
  color: #f2f2f2;
  box-shadow: 0px 0px 0px 10px rgb(0, 0, 0);
}
.App .font-banco {
  font-family: Banco;
}
.App a {
  text-decoration: none;
  cursor: pointer;
}
.App .back-button {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  text-align: left;
  color: #704FF6;
  text-decoration: none;
  font-size: 1.5em;
}
.App .back-button:hover {
  text-decoration: underline;
}
.App .back-button img {
  width: 1.5em;
  height: 1.5em;
  filter: invert(60%) sepia(77%) saturate(739%) hue-rotate(177deg) brightness(100%) contrast(97%);
}
.App button,
.App img {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.App p {
  white-space: pre-wrap;
}
.App .basic-padding-container h1 {
  margin-top: 0;
}
.App .basic-padding-container p {
  margin: 20px auto;
  text-align: left;
  max-width: 50%;
}
.App .page-container {
  text-align: center;
  padding-top: 60px;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}
.App .page-container .test div {
  height: 100vh;
  background: #704FF6;
}
.App .page-container .test div:nth-of-type(even) {
  background: #4225B7;
}

@media screen and (min-width: 801px) {
  .App .basic-padding-container {
    padding: 0px 50px;
  }
}
@media screen and (max-width: 800px) {
  .App .basic-padding-container {
    padding: 0px 20px;
  }
  .App .basic-padding-container p {
    max-width: 100%;
  }
}/*# sourceMappingURL=App.css.map */