@import "universal.scss";

.post {

  .post-container {

    .thumbnail {
      object-fit: cover;
      max-width: 100%;
      max-height: 70vh;
      border-radius: 20px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    }

    .post-content {
      .title {
        margin: 0;
      }

      .date-posted {
        font-size: 1.5em;
        color: $tertiary-text;
      }

      .body {
        font-size: 1.2em;
      }

      .related-items {
        margin-top: 50px;

        .product {
          margin: 0 auto;
          width: 200px;
        }
      }
    }

  }

}

// desktop
@media screen and (min-width: $desktop-breakpoint) {
  .post {
    padding: 20px 40px;

    .back-button {
      &:hover {
        text-decoration: underline;
      }
    }

    .post-container {
      @include flex(row);
      gap: 40px;

      .thumbnail {
        max-width: 50%;
        max-height: 70vh;
        border-radius: 20px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      }

      .post-content {
        text-align: left;

        .title {}

        .date-posted {}

        .body {}

        .related-items {
          width: 200px;

          .product {
            margin: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

// mobile
@media screen and (max-width: $mobile-breakpoint) {
  .post {
    padding: 0 20px;

    .thumbnail {
      margin-bottom: 20px;
    }

    .post-container {
      .post-content {
        .body {
          text-align: left;
        }
      }
    }
  }
}