// brand & basic colors
$main-light: #704FF6;
$main-dark: #4225B7;
$green: #1cd15f;
$red: #ed4040;

// image filters
$green-svg-filter: invert(58%) sepia(98%) saturate(409%) hue-rotate(89deg) brightness(94%) contrast(89%);
$red-svg-filter: invert(31%) sepia(97%) saturate(2201%) hue-rotate(339deg) brightness(95%) contrast(100%);
$white-svg-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(141deg) brightness(103%) contrast(101%);
$tertiary-svg-filter: invert(35%) sepia(9%) saturate(8%) hue-rotate(353deg) brightness(88%) contrast(85%);

// backgrounds and foregrounds
$void: #030303;
$background: #161616;
$foreground: #222222;
$foreground-emphasis: #333333;

// text colors
$primary-text: #f2f2f2;
$primary-text-emphasis: #fff;
$secondary-text: #E0E0E0;
$tertiary-text: #585858;

// screen width breakpoints
$desktop-breakpoint: 801px;
$mobile-breakpoint: 800px;
$max-app-width: 1500px;

@mixin flex($direction) {
  display: flex;
  flex-direction: $direction;
}