@import "universal.scss";

@font-face {
  font-family: "Kalam";
  src: url("../fonts/Kalam/Kalam-Regular.ttf");
}

.hero {
  position: relative;
  margin-top: -30px;
  width: 100%;
  height: 350px;
  background-color: $foreground;
  background-size: cover;
  box-shadow: inset 0 -150px 100px 20px rgba(0, 0, 0, .75);
  user-select: none;

  .hero-content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px 80px;
    text-align: left;

    .headline {
      display: block;
      font-family: Kalam;
      font-size: 5em;
      color: $main-light;
      filter: drop-shadow(0 0 4px rgba($void, 1));
    }

    .subheadline {
      display: block;
      color: $secondary-text;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, .5));
    }
  }
}

// desktop
@media screen and (min-width: $desktop-breakpoint) {
  .hero {
    .hero-content {
      .headline {
        font-size: 5em;
      }

      .subheadline {
        margin-top: -30px;
        font-size: 2em;
      }
    }
  }
}

// mobile
@media screen and (max-width: $mobile-breakpoint) {
  .hero {
    height: auto;

    .hero-content {
      position: relative;
      padding: 60px 40px;
      text-align: center;

      .headline {
        font-size: 4em;
        line-height: 100%;
      }

      .subheadline {
        font-size: 2em;
      }
    }
  }
}