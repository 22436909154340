@import "../Resources/css/universal.scss";

.checkout {
  padding: 0 40px;

  h1 {
    text-align: center;
    font-weight: lighter;
  }

  input[type="text"],
  input[type="email"],
  input[type="phone"] {
    display: block;
    margin: 10px auto;
    padding: 5px;
    border: none;
    outline: none;
    background-color: $foreground;
    color: inherit;
    border-bottom: 2px solid $tertiary-text;
    font-size: 1.2em;
    font-family: inherit;
    text-align: center;
    width: 200px;
  }

  button {
    border: none;
    outline: none;
    margin: 20px 10px;
    padding: 10px 15px;
    background: linear-gradient(45deg, $main-dark, $main-light);
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    font-size: 1.2em;
    color: white;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    font-family: inherit;

    span {
      font-size: 2em;
      color: $primary-text-emphasis;
      filter: drop-shadow(0 0 10px rgba(0, 0, 0, 1));
    }

    &:active {
      transform: scale(.95);
    }

    &:disabled {
      opacity: .5;
      cursor: not-allowed;

      &:active {
        transform: none;
      }
    }
  }

  .cart-items {
    @include flex(row);
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;

    .item-card {
      background-color: $foreground;
      padding: 20px;
      max-width: 200px;

      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        margin-bottom: 20px;
      }

      .item-details {
        >* {
          display: block;
          text-align: left;
        }

        .name {
          font-size: 1.2em;
          font-weight: 600;
        }

        .type {
          color: $secondary-text !important;
          margin-bottom: 20px;
        }
      }

      .item-actions {
        margin-top: 20px;
        color: $red;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .cart-total {
    @include flex(column);
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-top: 40px;

    h2 {
      margin: 0;
    }

    div>div {
      @include flex(row);
      gap: 5px;
      justify-content: center;

      &.total {
        margin-top: 10px;
        padding: 5px 5px 0;
        border-top: dashed 2px $tertiary-text;
        font-size: 1.5em;
        font-weight: 600;
      }
    }

    button {
      &:disabled {
        opacity: .5;
        cursor: not-allowed;

        &:active {
          transform: none;
        }
      }
    }
  }
}