@import "./resources/styles/universal.scss";

@font-face {
  font-family: "Nunito Sans";
  src: url("./resources/fonts/Nunito_Sans/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf");
}

@font-face {
  font-family: Alata;
  src: url("./resources/fonts/Alata/Alata-Regular.ttf");
}

@font-face {
  font-family: Banco;
  src: url("./resources/fonts/Banco/Banco-Regular.ttf");
}

html,
body {
  background-color: $void !important;
  background-image: url("https://global.discourse-cdn.com/pocketgems/uploads/episodeinteractive/original/4X/9/5/0/9501d4ae192161a7042de423032df3e9228b7624.jpeg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.App {
  margin: 0 auto;
  max-width: $max-app-width;
  width: 100%;
  font-family: Nunito Sans;
  font-size: 18px;
  color: $primary-text;
  box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 1);

  .font-banco {
    font-family: Banco;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .back-button {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
    text-align: left;
    color: $main-light;
    text-decoration: none;
    font-size: 1.5em;

    &:hover {
      text-decoration: underline;
    }

    img {
      width: 1.5em;
      height: 1.5em;
      filter: invert(60%) sepia(77%) saturate(739%) hue-rotate(177deg) brightness(100%) contrast(97%);
    }
  }


  button,
  img {
    user-select: none;
  }

  p {
    white-space: pre-wrap;
  }

  .basic-padding-container {
    h1 {
      margin-top: 0;
    }

    p {
      margin: 20px auto;
      text-align: left;
      max-width: 50%;
    }
  }

  .page-container {
    text-align: center;
    padding-top: 60px;
    width: 100%;
    max-width: $max-app-width;
    margin: 0 auto;

    .test {
      div {
        height: 100vh;
        background: $main-light;

        &:nth-of-type(even) {
          background: $main-dark;
        }
      }
    }
  }
}

// desktop
@media screen and (min-width: $desktop-breakpoint) {
  .App {

    .basic-padding-container {
      padding: 0px 50px;
    }
  }
}

// mobile
@media screen and (max-width: $mobile-breakpoint) {
  .App {

    .basic-padding-container {
      padding: 0px 20px;

      p {
        max-width: 100%;
      }
    }
  }
}