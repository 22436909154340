@import "universal.scss";

.footer {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  // background-color: $void;
  bottom: 0;

  a {
    color: $primary-text;
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    &:nth-of-type(2) {
      margin-top: 20px;
    }

    &.contact {
      li {
        margin-bottom: 5px;
      }
    }

    li {
      @include flex(row);
      margin: 0;
      padding: 0;

      &:nth-of-type(1) {
        font-size: 1.1em;
        font-weight: bold;
        opacity: 1;
      }

      a {
        @include flex(row);
        justify-content: center;
        align-items: center;
        gap: 5px;
        opacity: .8;

        &:hover {
          opacity: 1;
        }

        img {
          height: 1.2em;
          vertical-align: center;
          filter: invert(100%) sepia(0%) saturate(7475%) hue-rotate(66deg) brightness(108%) contrast(98%);
        }
      }
    }
  }

  .copyright {
    display: block;
    margin-top: 20px;
    font-size: .8em;
    color: $tertiary-text;
  }
}

// desktop
@media screen and (min-width: $desktop-breakpoint) {
  .footer {
    flex-direction: row;
    gap: 40px;
    padding: 50px;

    .logo {
      img {
        width: 100px;
      }
    }

    ul {
      text-align: left;
    }

    .copyright {
      text-align: left;
    }
  }
}

// mobile
@media screen and (max-width: $mobile-breakpoint) {
  .footer {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 30px;

    .logo {
      img {
        width: 60px;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      text-align: center;

      li {
        justify-content: center;
      }
    }
  }
}