.checkout {
  padding: 0 40px;
}
.checkout h1 {
  text-align: center;
  font-weight: lighter;
}
.checkout input[type=text],
.checkout input[type=email],
.checkout input[type=phone] {
  display: block;
  margin: 10px auto;
  padding: 5px;
  border: none;
  outline: none;
  background-color: #222222;
  color: inherit;
  border-bottom: 2px solid #585858;
  font-size: 1.2em;
  font-family: inherit;
  text-align: center;
  width: 200px;
}
.checkout button {
  border: none;
  outline: none;
  margin: 20px 10px;
  padding: 10px 15px;
  background: linear-gradient(45deg, #4225B7, #704FF6);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-size: 1.2em;
  color: white;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-family: inherit;
}
.checkout button span {
  font-size: 2em;
  color: #fff;
  filter: drop-shadow(0 0 10px rgb(0, 0, 0));
}
.checkout button:active {
  transform: scale(0.95);
}
.checkout button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.checkout button:disabled:active {
  transform: none;
}
.checkout .cart-items {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}
.checkout .cart-items .item-card {
  background-color: #222222;
  padding: 20px;
  max-width: 200px;
}
.checkout .cart-items .item-card img {
  width: 100%;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: 20px;
}
.checkout .cart-items .item-card .item-details > * {
  display: block;
  text-align: left;
}
.checkout .cart-items .item-card .item-details .name {
  font-size: 1.2em;
  font-weight: 600;
}
.checkout .cart-items .item-card .item-details .type {
  color: #E0E0E0 !important;
  margin-bottom: 20px;
}
.checkout .cart-items .item-card .item-actions {
  margin-top: 20px;
  color: #ed4040;
  cursor: pointer;
}
.checkout .cart-items .item-card .item-actions:hover {
  text-decoration: underline;
}
.checkout .cart-total {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-top: 40px;
}
.checkout .cart-total h2 {
  margin: 0;
}
.checkout .cart-total div > div {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
}
.checkout .cart-total div > div.total {
  margin-top: 10px;
  padding: 5px 5px 0;
  border-top: dashed 2px #585858;
  font-size: 1.5em;
  font-weight: 600;
}
.checkout .cart-total button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.checkout .cart-total button:disabled:active {
  transform: none;
}/*# sourceMappingURL=Checkout.css.map */