@import "universal.scss";

.product {
  @include flex(column);
  align-items: center;
  box-shadow: 0 4px 5px 5px rgba(0, 0, 0, 0.1);

  &.out-of-stock {
    opacity: .5;
    user-select: none;
  }

  .product-name {
    color: $primary-text-emphasis;
  }

  .product-info {
    @include flex(row);
    gap: 5px;
    margin-top: 5px;
    color: $secondary-text;

    .separator {
      color: $tertiary-text;
    }
  }

  .item-image-container {
    position: relative;
    margin-bottom: 20px;

    &:hover {
      .button-wrapper {
        opacity: 1;
      }
    }

    @media screen and (max-width: $mobile-breakpoint) {
      .button-wrapper {
        opacity: 1 !important;
      }
    }

    .item-images {

      img.product-image {
        width: 100%;
        filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.4));
        transition: .5s filter;
        cursor: pointer;
      }
    }

    .button-wrapper {
      @include flex(row);
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 0;
      height: 100%;
      opacity: 0;

      &.prev-image-wrapper {
        left: 0;
      }

      &.next-image-wrapper {
        right: 0;
      }

      img {
        filter: $white-svg-filter drop-shadow(0 4px 5px rgba(0, 0, 0, 0.4));
        height: 2.5em;
        width: 2.5em;
        cursor: pointer;

        &:active {
          transform: scale(.8);
        }
      }
    }

  }

  .details-selector {
    padding: 0 10px;
    border-top: dotted 2px $tertiary-text;
    margin-top: 15px;

    // wrapper for input and select
    div {
      margin: 10px 0;
    }

    input,
    select {
      margin: 0 5px;
      border: none;
      outline: none;
      background-color: $foreground-emphasis;
      font-family: inherit;
      color: inherit;
    }

    .confirm-cancel-buttons {
      @include flex(row);
      justify-content: center;
      padding: 0 15px;
      border-top: dotted 2px $tertiary-text;

      button {
        margin: 0;
      }
    }
  }


  .cart-button {
    @include flex(row);
    align-items: center;
    margin-top: 20px;
    background: none;
    border: none;
    outline: none;
    color: $green;
    cursor: pointer;

    &:active {
      transform: scale(.95);
    }

    &:disabled {
      color: $tertiary-text;
      cursor: not-allowed;

      &:active {
        transform: none;
      }
    }

    &.confirm {
      filter: $green-svg-filter;
    }

    &.cancel {
      filter: $tertiary-svg-filter;
    }

    &.in-cart {
      color: $red;

      img {
        filter: invert(47%) sepia(53%) saturate(7425%) hue-rotate(342deg) brightness(103%) contrast(86%);
      }
    }

    img {
      height: 1.5em;
      width: 1.5em;
      filter: invert(56%) sepia(94%) saturate(395%) hue-rotate(89deg) brightness(95%) contrast(96%);
    }
  }
}

// destop
@media screen and (min-width: $desktop-breakpoint) {
  .product {
    padding: 20px;
    margin: 20px;
  }
}

// mobile
@media screen and (max-width: $mobile-breakpoint) {
  .product {
    margin: 10px;
    padding: 10px;
  }
}