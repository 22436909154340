.launch {
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  height: 100vh;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.launch .message img {
  width: 200px;
}
.launch .message p {
  font-size: 1.5em;
}
.launch .countdown .time-remaining {
  font-size: 3em;
}
.launch .countdown .time-remaining .label {
  color: #585858;
}

@media screen and (min-width: 801px) {
  .launch {
    display: flex;
    flex-direction: row;
    padding: 100px;
  }
  .launch .message {
    width: 50%;
    padding: 40px;
  }
  .launch .message img {
    width: 200px;
  }
  .launch .countdown {
    width: 50%;
    padding: 40px;
  }
}
@media screen and (max-width: 800px) {
  .launch {
    display: flex;
    flex-direction: column;
    padding: 40px;
  }
  .launch .message {
    text-align: center;
  }
  .launch .message img {
    margin: 0 auto;
  }
}/*# sourceMappingURL=Launch.css.map */