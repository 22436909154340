.footer {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
}
.footer a {
  color: #f2f2f2;
  text-decoration: none;
}
.footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer ul:nth-of-type(2) {
  margin-top: 20px;
}
.footer ul.contact li {
  margin-bottom: 5px;
}
.footer ul li {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
.footer ul li:nth-of-type(1) {
  font-size: 1.1em;
  font-weight: bold;
  opacity: 1;
}
.footer ul li a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  opacity: 0.8;
}
.footer ul li a:hover {
  opacity: 1;
}
.footer ul li a img {
  height: 1.2em;
  vertical-align: center;
  filter: invert(100%) sepia(0%) saturate(7475%) hue-rotate(66deg) brightness(108%) contrast(98%);
}
.footer .copyright {
  display: block;
  margin-top: 20px;
  font-size: 0.8em;
  color: #585858;
}

@media screen and (min-width: 801px) {
  .footer {
    flex-direction: row;
    gap: 40px;
    padding: 50px;
  }
  .footer .logo img {
    width: 100px;
  }
  .footer ul {
    text-align: left;
  }
  .footer .copyright {
    text-align: left;
  }
}
@media screen and (max-width: 800px) {
  .footer {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 30px;
  }
  .footer .logo img {
    width: 60px;
  }
  .footer ul {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .footer ul li {
    justify-content: center;
  }
}/*# sourceMappingURL=Footer.css.map */