@import "universal.scss";

.navigation-bar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 60px;
  background-color: $void;
  text-align: center;
  user-select: none;

  .branding {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 0 10px;
    box-sizing: border-box;
    width: 100%;
    height: 60px;

    img {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer;
    }
  }

  .cart-area {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    width: 60px;
    padding: 15px;
    cursor: pointer;

    .item-alert {
      display: block;
      margin-top: 5px;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: $red;
      transform: translate(-5px, -18px);
      border: solid 4px $void;
      transition: .5s opacity;

      &.disabled {
        opacity: 0;
      }
    }

    img {
      width: 100%;
      height: 100%;
      filter: invert(100%) sepia(0%) saturate(7451%) hue-rotate(302deg) brightness(94%) contrast(105%);
    }
  }

  .notification-banner {
    padding: 5px 0;
    width: 100%;
    background: linear-gradient(to right, $main-dark, $main-light, $main-dark);
    color: $primary-text;
    border-bottom: solid 5px $void;

    .banner-text {
      filter: drop-shadow(0 0 3px $void);
    }
  }
}

// desktop
@media screen and (min-width: $desktop-breakpoint) {
  .navigation-bar {

    .branding {
      img {
        transition: .5s transform;
      }
    }

    &:hover {
      .branding {
        img {
          transform: scale(1.25) translate(0, 5px);
        }
      }

      .navigation-wrapper {
        max-height: 60px;

        .navigation {
          opacity: 1;
        }
      }
    }

    .navigation-wrapper {
      max-height: 0;
      overflow: hidden;
      transition: .5s max-height;
      background-color: $void;

      .navigation {
        @include flex(row);
        justify-content: center;
        gap: 10px;
        padding-bottom: 20px;
        opacity: 0;
        transition: .5s opacity;

        font-size: 1.3em;
        a {
          color: $primary-text;
          text-decoration: none;
        }
      }
    }

    .cart-area {
      right: 0;
    }

    .nav-mobile-toggle {
      display: none;
    }
  }
}

// mobile
@media screen and (max-width: $mobile-breakpoint) {
  .navigation-bar {
    .branding {}

    .cart-area {
      right: 40px;
    }

    .nav-mobile-toggle {
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
      box-sizing: border-box;
      width: 60px;
      height: 60px;

      img {
        width: 100%;
        filter: invert(100%) sepia(0%) saturate(7451%) hue-rotate(302deg) brightness(94%) contrast(105%);
      }
    }

    &.expanded {
      .navigation {
        height: auto;
      }
    }

    .navigation {
      background-color: $void;
      font-size: 2em;
      height: 0;
      overflow: hidden;

      a {
        display: block;
        padding: 10px 0;
        color: $primary-text;
        text-decoration: none;

        &:nth-last-of-type(1) {
          padding-bottom: 20px;
        }
      }
    }
  }
}