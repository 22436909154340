.shop-filter {
  text-align: left;
}
.shop-filter h2 {
  margin: 0;
  margin-bottom: 10px;
}
.shop-filter .option {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  gap: 5px;
  font-size: 1.2em;
}
.shop-filter .option input[type=checkbox] {
  height: 1.2em;
  width: 1.2em;
}
.shop-filter .option input[type=checkbox]:checked {
  background: #1cd15f;
}
.shop-filter button {
  margin-top: 20px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  background: #222222;
  color: #f2f2f2;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.shop-filter button:active {
  transform: scale(0.95);
}

@media screen and (min-width: 801px) {
  .shop-filter {
    padding: 20px;
    padding-left: 40px;
  }
  .shop-filter .mobile-filter-toggle {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .shop-filter {
    padding: 0px 30px 20px;
    font-size: 1.5em;
  }
  .shop-filter .mobile-filter-toggle button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .shop-filter .mobile-filter-toggle button img {
    width: 1.5em;
    height: 1.5em;
    filter: invert(100%) sepia(3%) saturate(205%) hue-rotate(317deg) brightness(116%) contrast(90%);
  }
  .shop-filter .filter-options {
    margin-top: 20px;
  }
  .shop-filter .filter-options.hidden {
    display: none;
  }
}/*# sourceMappingURL=ShopFilter.css.map */