.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 5px 5px rgba(0, 0, 0, 0.1);
}
.product.out-of-stock {
  opacity: 0.5;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.product .product-name {
  color: #fff;
}
.product .product-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 5px;
  color: #E0E0E0;
}
.product .product-info .separator {
  color: #585858;
}
.product .item-image-container {
  position: relative;
  margin-bottom: 20px;
}
.product .item-image-container:hover .button-wrapper {
  opacity: 1;
}
@media screen and (max-width: 800px) {
  .product .item-image-container .button-wrapper {
    opacity: 1 !important;
  }
}
.product .item-image-container .item-images img.product-image {
  width: 100%;
  filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.4));
  transition: 0.5s filter;
  cursor: pointer;
}
.product .item-image-container .button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  opacity: 0;
}
.product .item-image-container .button-wrapper.prev-image-wrapper {
  left: 0;
}
.product .item-image-container .button-wrapper.next-image-wrapper {
  right: 0;
}
.product .item-image-container .button-wrapper img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(141deg) brightness(103%) contrast(101%) drop-shadow(0 4px 5px rgba(0, 0, 0, 0.4));
  height: 2.5em;
  width: 2.5em;
  cursor: pointer;
}
.product .item-image-container .button-wrapper img:active {
  transform: scale(0.8);
}
.product .details-selector {
  padding: 0 10px;
  border-top: dotted 2px #585858;
  margin-top: 15px;
}
.product .details-selector div {
  margin: 10px 0;
}
.product .details-selector input,
.product .details-selector select {
  margin: 0 5px;
  border: none;
  outline: none;
  background-color: #333333;
  font-family: inherit;
  color: inherit;
}
.product .details-selector .confirm-cancel-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 15px;
  border-top: dotted 2px #585858;
}
.product .details-selector .confirm-cancel-buttons button {
  margin: 0;
}
.product .cart-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  background: none;
  border: none;
  outline: none;
  color: #1cd15f;
  cursor: pointer;
}
.product .cart-button:active {
  transform: scale(0.95);
}
.product .cart-button:disabled {
  color: #585858;
  cursor: not-allowed;
}
.product .cart-button:disabled:active {
  transform: none;
}
.product .cart-button.confirm {
  filter: invert(58%) sepia(98%) saturate(409%) hue-rotate(89deg) brightness(94%) contrast(89%);
}
.product .cart-button.cancel {
  filter: invert(35%) sepia(9%) saturate(8%) hue-rotate(353deg) brightness(88%) contrast(85%);
}
.product .cart-button.in-cart {
  color: #ed4040;
}
.product .cart-button.in-cart img {
  filter: invert(47%) sepia(53%) saturate(7425%) hue-rotate(342deg) brightness(103%) contrast(86%);
}
.product .cart-button img {
  height: 1.5em;
  width: 1.5em;
  filter: invert(56%) sepia(94%) saturate(395%) hue-rotate(89deg) brightness(95%) contrast(96%);
}

@media screen and (min-width: 801px) {
  .product {
    padding: 20px;
    margin: 20px;
  }
}
@media screen and (max-width: 800px) {
  .product {
    margin: 10px;
    padding: 10px;
  }
}/*# sourceMappingURL=ProductCard.css.map */