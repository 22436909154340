.shop .item-preview {
  display: flex;
  flex-direction: row;
  z-index: 999;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  cursor: pointer;
}
.shop .item-preview.hidden {
  display: none;
}
.shop .item-preview img {
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 60%;
  max-height: 80%;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
}
.shop .shop-container .products-container {
  display: grid;
}
.shop .page-selector {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.shop .page-selector span {
  font-size: 1.5em;
}
.shop .page-selector button {
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.shop .page-selector button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.shop .page-selector button img {
  height: 100%;
  width: 100%;
  filter: invert(100%) sepia(3%) saturate(205%) hue-rotate(317deg) brightness(116%) contrast(90%);
}
@media screen and (min-width: 801px) {
  .shop .shop-container {
    display: flex;
    flex-direction: row;
    padding-top: 40px;
  }
  .shop .shop-container .products-container {
    flex: 5;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 0 40px;
  }
  .shop .shop-container .products-container .product {
    margin: 20px;
    padding: 20px;
  }
}
@media screen and (max-width: 800px) {
  .shop .shop-container {
    display: block;
    padding-top: 20px;
  }
  .shop .shop-container .products-container {
    padding: 0 20px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}/*# sourceMappingURL=Shop.css.map */